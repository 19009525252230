import React, { Component } from 'react';
import { apiCall } from '../utils/api'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import RiderModal from './RiderModal';

class Riders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riders: [],
      selectedRider: null,
      riderImages: {} // Store image URLs for riders
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchRiders();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.season.gp_season_id !== this.props.season.gp_season_id) {
      this.state.selectedRider = 0
    }
  }

  checkImage(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  async getRiderImageUrl(riderId) {
    var img = `https://gp.anthonybois.fr/img/pilotes/${this.props.season.gp_season_name}/${String(riderId).padStart(2, '0')}.png`
    if(await this.checkImage(img)) {
      return img;
    } else {
      return `https://gp.anthonybois.fr/img/pilotes/no-rider.png`
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.season.gp_season_id !== this.props.season.gp_season_id) {
      this.fetchRiders();
    }
  }

  fetchRiders() {
    const { season } = this.props;
    apiCall(`get_datas_by_riders&season_id=${season.gp_season_id}`)
      .then(async ridersData => {
        const ridersArray = Object.values(ridersData.data);
        
        // Preload rider images
        const riderImages = {};
        for (const rider of ridersArray) {
          riderImages[rider.gp_riders_id] = await this.getRiderImageUrl(rider.gp_riders_id);
        }

        this.setState({ 
          riders: ridersArray,
          riderImages 
        });
      })
      .catch(error => {
        console.error('Error fetching riders:', error);
      });
  }

  handleRiderClick = (rider) => {
    this.setState({ selectedRider: rider });
  }
  
  render() {
    const { season } = this.props;
    const { riders, selectedRider, riderImages } = this.state;
    return (
      <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="fw-bold">{season.gp_season_name}</h2>
        <div className="text-muted">
          <i className="bi bi-trophy me-2"></i>
          <span>{riders.length} Riders</span>
        </div>
      </div>
      
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4">
        {riders.map((rider) => (
          <div key={rider.gp_riders_id} className="col">
            <div 
              className="card h-100 rider-card" 
              onClick={() => this.handleRiderClick(rider)}
              style={{ cursor: 'pointer' }}
            >
              <div 
                className="card-img-top position-relative" 
                style={{ 
                  height: '210px', 
                  overflow: 'hidden', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'flex-end' 
                }}
              >
                <img 
                  src={riderImages[rider.gp_riders_id]} 
                  alt={`${rider.gp_riders_firstname} ${rider.gp_riders_name}`}
                  className="w-100"
                  style={{ 
                    objectFit: 'cover', 
                    objectPosition: 'center top', 
                    maxHeight: '200px',
                    width: 'auto'
                  }}
                />
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5 className="card-title mb-0">#{rider.gp_riders_id}</h5>
                  <i className="bi bi-person text-muted"></i>
                </div>
                <h6 className="card-subtitle mb-2 text-muted">
                  {rider.gp_riders_firstname} {rider.gp_riders_name}
                </h6>
                <p className="card-text text-muted small">
                  {rider.gp_team_name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <RiderModal 
        rider_datas={selectedRider} 
        seasonId={season.gp_season_id} 
        seasonName={season.gp_season_name} 
        modalRef={this.modalRef} 
      />
    </div>
  );
}
}

export default Riders;