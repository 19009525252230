import React, { useState } from 'react';
import { apiCall } from '../utils/api';
import 'bootstrap/dist/css/bootstrap.min.css';

const API_BASE_URL = 'https://gp.anthonybois.fr/api/api.php?';

const documentation = await apiCall('get_api_documentation');
const API_FUNCTIONS = documentation.data;

const Api = () => {
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [apiParams, setApiParams] = useState({});
  const [apiResponse, setApiResponse] = useState(null);

  const handleParamChange = (paramName, value) => {
    setApiParams(prev => ({
      ...prev,
      [paramName]: value
    }));
  };

  const executeApiCall = async () => {
    if (!selectedFunction) return;

    const paramString = Object.entries(apiParams)
      .map(([key, value]) => `&${key}=${value}`)
      .join('');

    try {
      const response = await apiCall(`${selectedFunction.name}${paramString}`);
      setApiResponse(JSON.stringify(response, null, 2));
    } catch (error) {
      setApiResponse(`Error: ${error.response?.data ? JSON.stringify(error.response.data, null, 2) : error.message}`);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3 p-3">
          <div className="mt-3">
              <strong>Base URL:</strong>
              <p>{API_BASE_URL}</p>
          </div>

          <h4>API Functions</h4>
          <div className="list-group">
            {API_FUNCTIONS.map(func => (
              <button
                key={func.name}
                className={`list-group-item list-group-item-action ${selectedFunction?.name === func.name ? 'active' : ''}`}
                onClick={() => setSelectedFunction(func)}
              >
                {func.name}
              </button>
            ))}
          </div>
        </div>
        <div className="col-9 p-4">
          {selectedFunction && (
            <div>
              <h2>{selectedFunction.name}</h2>
              <p>{selectedFunction.description}</p><br /><br /><br />
              { selectedFunction.parameters.length > 0 && (
                <div>
                  <h4>Parameters</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Required</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedFunction.parameters.map(param => (
                        <tr key={param.name}>
                          <td>{param.name}</td>
                          <td>{param.type}</td>
                          <td>{param.required ? 'Yes' : 'No'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table><br /><br />
                </div>
              )}

              <h4>Example Call</h4>
              <a target="_blank" href={API_BASE_URL + selectedFunction.example}>{API_BASE_URL + selectedFunction.example}</a>
              <br /><br /><br />
              <h4>API Call Tester</h4>
              <form>
                {selectedFunction.parameters.map(param => (
                  <div key={param.name} className="mb-3">
                    <label className="form-label">{param.name}</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleParamChange(param.name, e.target.value)}
                      required={param.required}
                    />
                  </div>
                ))}
                <button 
                  type="button" 
                  className="btn btn-primary"
                  onClick={executeApiCall}
                >
                  Execute API Call
                </button>
              </form>

              {apiResponse && (
                <div className="mt-4">
                  <h4>API Response</h4>
                  <pre className="bg-secondary text-light p-3">{(apiResponse)}</pre>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Api;