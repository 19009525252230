import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { apiCall } from '../utils/api';
import { withTranslation } from 'react-i18next';

class Evolution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manufacturerStats: {},
      selectedMetric: 'points_per_bike'
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    apiCall('get_manufacturer_stats')
      .then(data => {
        this.setState({ manufacturerStats: data.data }, () => {
          this.createChart();
        });
      })
      .catch(error => {
        console.error('Error fetching manufacturer stats:', error);
      });
  }

  createChart() {
    const { manufacturerStats, selectedMetric } = this.state;
    const { t } = this.props;

    const seasons = Object.keys(manufacturerStats)
    const manufacturers = [...new Set(
      seasons.flatMap(season => Object.keys(manufacturerStats[season]))
    )];
    var finalPoints = 0;
    const series = manufacturers.map(manufacturer => {
      const data = seasons.map(season => {
        const stats = manufacturerStats[season][manufacturer];
        finalPoints = manufacturerStats[season][manufacturer]?.total_points || 0
        return stats ? parseFloat(stats[selectedMetric]) : null;
      });

      var visible = false
      if(['Honda','Yamaha','Ducati','KTM','Aprilia'].includes(manufacturer)) {
        visible = true
      }

      return {
        visible: visible,
        name: manufacturer,
        data: data,
        finalPoints: finalPoints
      };
    });

    series.sort((a, b) => (b.finalPoints || 0) - (a.finalPoints || 0));



    Highcharts.chart('manufacturer-chart', {
      chart: {
        zoomType: 'x',
        type: 'line',
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
          stops: [
            [0, '#2a2a2b'],
            [1, '#3e3e40']
          ]
        }
      },
      title: {
        text: selectedMetric === 'total_points' 
          ? t('Nombre points total')
          : t('Nombre points en prenant en compte le nombre de motos'),
        style: {
          color: '#E0E0E3'
        }
      },
      xAxis: {
        categories: seasons,
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3'
          }
        }
      },
      yAxis: {
        title: {
          text: 'Nb Points',
          style: {
            color: '#E0E0E3'
          }
        },
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3'
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        itemStyle: {
          color: '#E0E0E3'
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          },
          lineWidth: 3
        }
      },
      colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
        '#eeaaee', '#55BF3B', '#DF5353', '#7890BA', '#aFee88'
      ],
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
          color: '#F0F0F0'
        }
      },
      series: series,
      responsive: {
        rules: [{
            condition: {
            maxWidth: 500
            },
            chartOptions: {
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            }
            }
        }]
      },
    });
  }

  handleMetricChange = (metric) => {
    this.setState({ selectedMetric: metric }, () => {
      this.createChart();
    });
  };

  render() {
    const { t } = this.props;
    const { selectedMetric } = this.state;

    return (
      <div className="container">
        <div className="row mb-4">
          <div className="col">
            <div className="btn-group" role="group">
              <button 
                className={`btn ${selectedMetric === 'total_points' ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => this.handleMetricChange('total_points')}
              >
                {t('Nombre points total')}
              </button>
              <button 
                className={`btn ${selectedMetric === 'points_per_bike' ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => this.handleMetricChange('points_per_bike')}
              >
                {t('Nombre points en prenant en compte le nombre de motos')}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div id="manufacturer-chart" style={{ minHeight: '500px' }}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Evolution);