import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { apiCall } from '../utils/api';
import { Map, Marker } from "pigeon-maps";
import { withTranslation } from 'react-i18next';

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      openAccordionId: null
    };
  }

  componentDidMount() {
    this.fetchSchedule();
  }

  fetchSchedule = () => {
    apiCall('get_schedule')
      .then(response => {
        const scheduleArray = Object.values(response.data);
        this.setState({ schedule: scheduleArray }, () => {
          this.scrollToNextEvent(scheduleArray);
        });
      })
      .catch(error => {
        console.error('Error fetching schedule:', error);
      });
  };

  scrollToNextEvent = (scheduleArray) => {
    const currentDate = new Date();
    const nextEventDate = scheduleArray.find(race => new Date(race.date_begin) > currentDate);

    if (nextEventDate) {
      const element = document.getElementById(`accordion-${nextEventDate.date_begin}`);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        this.setOpenAccordion(nextEventDate.date_begin);
      }
    }
  };

  setOpenAccordion = (accordionId) => {
    this.setState({ openAccordionId: accordionId });
  };

  render() {
    const { schedule, openAccordionId } = this.state;
    const { t } = this.props; // Obtenez la fonction de traduction à partir des props

    return (
      <div className="container">
        {schedule.map((race, index) => (
          <RaceItem key={index} race={race} isOpen={race.date_begin === openAccordionId} setOpenAccordion={this.setOpenAccordion} t={t} />
        ))}
      </div>
    );
  }
}

function RaceItem({ race, isOpen, setOpenAccordion, t }) {
  var lat = parseFloat(race.gp_circuit_long);
  var lon = parseFloat(race.gp_circuit_lat);

  const toggleAccordion = () => {
    setOpenAccordion(isOpen ? null : race.date_begin);
  };

  return (
    <div className="accordion" id={`accordion-${race.date_begin}`} data-date={race.date_begin}>
      <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
        <h2 className="accordion-header" id={`heading-${race.date_begin}`}>
          <button className={`accordion-button ${isOpen ? 'collapsed' : ''}`} type="button" onClick={toggleAccordion} aria-expanded={isOpen} aria-controls={`collapse-${race.date_begin}`}>
            <img src={`https://flagcdn.com/56x42/${race.country_iso_code}.png`} alt={race.country_iso_code} /> 
            &emsp;{race.date_begin} - {race.gp_gp_name}
          </button>
        </h2>
        <div id={`collapse-${race.date_begin}`} className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`} aria-labelledby={`heading-${race.date_begin}`} data-bs-parent={`#accordion-${race.date_begin}`}>
          <div className="accordion-body">
            <div className="row">
              <div className="col-4">
                <ul>
                  <li>{t('Circuit Name')}: {race.gp_circuit_name}</li>
                  <li>{t('Circuit Size')}: {race.gp_circuit_size}</li>
                </ul>
                {Object.entries(race.timetables).map(([sceance, time]) => (
                  <p key={sceance}>{sceance} : {time}</p>
                ))}
              </div>
              <div className="col-8">
                <Map height={300} defaultZoom={11} defaultCenter={[lat, lon]}>
                  <Marker width={50} anchor={[lat, lon]} />
                </Map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Agenda); // Enveloppez votre composant avec withTranslation
