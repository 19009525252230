// Menu.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faPerson, faCalendarDays, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import './Menu.scss';

function Menu() {
  const pages = ["welcome", "riders", "agenda", "evolution"];
  const icons = [faChartLine, faPerson, faCalendarDays, faArrowTrendUp];

  return (
    <div id="menu">
      {pages.map((page, index) => (
        <div key={index} className="menu-bubble">
          <a className="menu-icon" href={`?page=${page}`}>
            <FontAwesomeIcon icon={icons[index]} />
          </a>
        </div>
      ))}
    </div>
  );
}

export default Menu;
